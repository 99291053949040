import React from "react"
import { graphql } from "gatsby"
import { Section, Container } from "../components/utils"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const AboutPage = ({ data }) => {
  const { title, content, seo } = data.page
  return (
    <Layout>
      <Section padding="small" topOnly>
        <Container>
          <h1>{title}</h1>
        </Container>
        {content.map(block => (
          <div key={block.id}>
            {block.model.apiKey === "text" && (
              <Section padding="small">
                <Container>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: block.text,
                    }}
                  />
                </Container>
              </Section>
            )}
            {block.model.apiKey === "image" && (
              <Section padding="small" bottomOnly>
                <Container>
                  <Image fluid={block.image.fluid} alt={block.image.alt} />
                  <p className="caption">{block.caption}</p>
                </Container>
              </Section>
            )}
            {block.model.apiKey === "testimonial" && (
              <Testimonial>
                <Container>
                  <p className="quote">{block.testimonial}</p>
                  <p className="author">{block.author}</p>
                </Container>
              </Testimonial>
            )}
          </div>
        ))}
      </Section>
      <SEO title={seo.title} description={seo.description} />
    </Layout>
  )
}

export default AboutPage

const Testimonial = styled.div`
  padding: 2rem;
  background: ${props => props.theme.paleBlue};
  text-align: center;
  .quote {
    font-size: 2rem;
    &::before {
      content: open-quote;
      color: ${props => props.theme.blue};
      font-size: 3rem;
    }
    &::after {
      content: close-quote;
      color: ${props => props.theme.blue};
      font-size: 3rem;
    }
  }
  .author {
    color: ${props => props.theme.orange};
    &::before {
      content: "- ";
    }
  }
`
const Image = styled(Img)`
  margin-bottom: 1rem;
  & + .caption {
    color: ${props => props.theme.navy};
    font-size: 1.3rem;
    text-align: center;
  }
`

export const query = graphql`
  {
    page: datoCmsAboutPage {
      title
      content {
        ... on DatoCmsText {
          id
          model {
            apiKey
          }
          text
        }
        ... on DatoCmsImage {
          id
          model {
            apiKey
          }
          image {
            fluid {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
          caption
        }
        ... on DatoCmsTestimonial {
          id
          model {
            apiKey
          }
          author
          testimonial
        }
      }
      seo {
        title
        description
      }
    }
  }
`
